import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatNativeDateModule,
  MatIconModule,
  MatInputModule,
  MatSidenavModule,
  MatListModule,
  MatDialogModule
} from "@angular/material";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./guards/Auth.guard";

import { MatDialog } from "@angular/material/dialog";

/* Firebase services */
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
//import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "../environments/environment";
import { LoginComponent } from "./login/login.component";
/* Auth service */
import { AuthService } from "../services/auth.service";
import { SpeechRecognitionService } from "./../services/speech-recognition.service";

import { TasklistComponent } from "./tasklist/tasklist.component";
import { ConfirmDeleteDialog } from "./tasklist/tasklist.component";
import { SidenavListComponent } from "./navigation/sidenav-list/sidenav-list.component";
import { TaskeditorComponent } from "./taskeditor/taskeditor.component";
import { TTypeSelectComponent } from "./ttypeselect/ttypeselect.component";
import { ShoplistComponent } from "./shoplist/shoplist.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TasklistComponent,
    ConfirmDeleteDialog,
    TaskeditorComponent,
    TTypeSelectComponent,
    SidenavListComponent,
    ShoplistComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,

    MatButtonModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatInputModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(), //adds offline capability
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule // imports firebase/auth, only needed for auth features
  ],
  providers: [AuthService, AuthGuard, SpeechRecognitionService, MatDialog],
  entryComponents: [AppComponent, ConfirmDeleteDialog],
  bootstrap: [AppComponent]
})
export class AppModule {}
