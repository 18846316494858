import { Component, Inject, OnInit, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";

import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import * as firebase from "firebase/app";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { Task } from "../../models/Task";
import { TaskType } from "../../models/TaskType";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-tasklist",
  templateUrl: "./tasklist.component.html",
  styleUrls: ["./tasklist.component.css"]
})
export class TasklistComponent implements OnInit {
  tasksObs: Observable<{}[]>;
  //tasks: AngularFirestoreCollection<Task>;
  tasks: Task[];
  private taskDoc: AngularFirestoreDocument<Task>;
  userpath: string;
  tasksPath: string;
  taskTypesPath: string;

  constructor(
    private authservice: AuthService,
    private router: Router,
    private db: AngularFirestore,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userpath = this.authservice.currUserPath();

    this.tasksPath = this.userpath + "/tasks";
    this.tasksObs = this.db.collection<Task>(this.tasksPath).valueChanges();
  }

  setTaskTileStyles() {
    let styles = {
      // 'color':  this.isHoussein() ? 'red' : 'black',
      // 'font-size':  this.isDaniel() ? '3em' : '2em',
      //'font-style':  this.isDaniel() || this.isHoussein() ? 'italic' : 'normal',
      "border-color": "blue"
    };
    return styles;
  }

  openMenu() {
    this.router.navigate(["menu"]);
  }

  openTask(task: Task) {
    var ttype: TaskType;
    var ttypeID: string;
    ttypeID = task.TaskTypeID;
    this.taskTypesPath = "/TaskTypes";
    this.db
      .collection<TaskType>(this.taskTypesPath)
      .doc(task.TaskTypeID)
      .get()
      .subscribe(tt => {
        this.router.navigate([
          "/" + tt.data().BaseType,
          task.TaskID,
          tt.data().TaskTypeID
        ]);
      });
  }

  confirmDelete(task: Task) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialog, {
      height: "400px",
      width: "600px",
      data: task
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result > 0) {
        this.delete(result);
      }
    });
  }

  delete(taskID: number) {
    this.db
      .collection(this.authservice.currUserPath() + "/tasks/")
      .doc(taskID.toString())
      .delete();
  }

  onNewClicked() {
    this.router.navigate(["ttypes"]);
  }
}

@Component({
  selector: "confirm-delete",
  templateUrl: "ConfirmDeleteDialog.html"
})
export class ConfirmDeleteDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Task
  ) {}

  /*  onNoClick(): void {
    debugger;
    this.dialogRef.close();
  }

  onOKClick(): void {
    this.dialogRef.close();
  } */
}
