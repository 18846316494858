import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private user: Observable<firebase.User>;
  public currUser: firebase.User = null;
  public local: boolean = false;
  public redirectUrl: string;

  // BehaviorSubjects have an initial value.
  // isLoggedIn is property (not function) now:
  public isLoggedIn = new BehaviorSubject<boolean>(false);
  public isOffline = new BehaviorSubject<boolean>(false);
  /* first assume, that user is onine */

  constructor(private firebaseAuth: AngularFireAuth, private router: Router) {
    // so this.user is not required since it is reference to this.firebaseAuth
    this.firebaseAuth.authState.subscribe(
      user => {
        if (user) {
          this.currUser = user;
          this.isLoggedIn.next(true);

          // NOW, when the callback from firebase came, and user is logged in,
          // we can navigate to the attempted URL (if exists)
          if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
          }
        } else {
          this.isLoggedIn.next(false);
        }
      },
      error => {
        this.isOffline.next(true);
      }
    );
  }

  /* constructor(private _firebaseAuth: AngularFireAuth, 
             private router: Router) 
{ 
    this.user = this._firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.currUser = user;
        }
        else {
          this.currUser = null;
        }
      }
    );
} */

  currUserPath() {
    if (this.currUser == null) {
      return null;
    } else {
      //return this.currUser.email.replace(".", "_@@_");
      return "/users/" + this.currUser.email;
    }
    //return this.currUser.uid; // better, change for production!!
  }

  signInWithTwitter() {
    return this.firebaseAuth.auth.signInWithPopup(
      new firebase.auth.TwitterAuthProvider()
    );
  }

  signInWithFacebook() {
    return this.firebaseAuth.auth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    );
  }

  signInWithGoogle() {
    return this.firebaseAuth.auth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    );
  }

  localStorage() {
    this.local = true;
  }

  logout() {
    this.firebaseAuth.auth.signOut().then(res => this.router.navigate(["/"]));
  }
}
