import { Component, OnInit, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from "@angular/fire/firestore";

import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import * as firebase from "firebase/app";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { Task } from "../../models/Task";
import { TaskType } from "../../models/TaskType";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "ttypeselect",
  templateUrl: "./ttypeselect.component.html",
  styleUrls: ["./ttypeselect.component.css"]
})
export class TTypeSelectComponent implements OnInit {
  taskTypesObs: Observable<{}[]>;

  taskTypesPath: string;

  constructor(
    private db: AngularFirestore,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.taskTypesPath = "/TaskTypes";
    this.taskTypesObs = this.taskTypesObs = this.db
      .collection<Task>(this.taskTypesPath)
      .valueChanges();
  }

  ttypeSelected(tt: TaskType) {
    var newTaskID: number;
    var user: any;
    var obs: any;
    console.log(tt.BaseType);
    var docref = this.db.doc(this.auth.currUserPath());
    docref.get().subscribe(ds => {
      var currNo = ds.data().currTaskNo;
      docref.set({ currTaskNo: currNo + 1 });
      this.router.navigate(["/" + tt.BaseType, currNo, tt.TaskTypeID]);
      console.log(currNo);
    });
    //newTaskID = this.taskservice.createTask(tt.TaskTypeID);
    // obs = this.af.object("/User/" + this.as.currUserPath());
    // /* !!localAuth has to be vastly removed */
    // obs
    //   .valueChanges()
    //   .take(1)
    //   .subscribe(
    //     val => {
    //       user = val;
    //       if (!user) {
    //         user = {
    //           CurrTaskNo: -100,
    //           Mail: this.as.currUser.email,
    //           ID: this.as.currUser.getIdToken
    //         };
    //         obs.set(user);
    //         newTaskID = 10000;
    //       } else {
    //         newTaskID = user.CurrTaskNo;
    //       }
    //       obs.update({ CurrTaskNo: newTaskID + 1 });
    //       user.CurrTaskNo = newTaskID + 1;
    //       /* update local storage for every case */
    //       window.localStorage.setItem("user", JSON.stringify(user));
    //       this.router.navigate([
    //         "/" + tt.BaseTaskType,
    //         "T" + newTaskID,
    //         tt.TaskTypeID
    //       ]);
    //     },
    //     { error: {} }
    //   );
  }
}
