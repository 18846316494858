export class Task {
  public TaskID: number;
  public TaskStatus: number;
  public TaskTypeID: string;
  public TaskName: string;
  public TaskDesc: string;
  public TaskCreated: string;
  public TaskLastChanged: string;
  public dbop: string;
  public Items: Array<ShoppingItem>;

  public constructor(
    initTaskID: number,
    ttypeID?: string,
    withDefault?: boolean
  ) {
    this.TaskTypeID = ttypeID ? ttypeID : "ST";
    this.TaskStatus = 10;
    this.TaskID = initTaskID;
    this.dbop = "";
    this.TaskCreated = new Date().toLocaleString();
    this.TaskLastChanged = this.TaskCreated;

    if (withDefault != false) {
      this.TaskName = "myTask " + initTaskID.toString();
      this.TaskDesc =
        this.TaskName + " is described here in in all the availabble details";
    } else {
      this.TaskName = "";
      this.TaskDesc = "";
    }
    this.Items = [];
  }
}

export class ShoppingItem {
  public Issue: string;
  public Area: string;
  public DueDate: Date;

  public constructor() {}
}
