import { Component, OnInit, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  error: any;
  constructor(private authservice: AuthService, private router: Router) {}

  ngOnInit() {}

  onGoogleLogin() {
    this.authservice.signInWithGoogle().then(data => {
      this.router.navigate(["tasks"]);
    });
  }

  onFacebookLogin() {
    this.authservice.signInWithTwitter();
  }

  onTwitterLogin() {
    this.authservice.signInWithTwitter().then();
  }

  onLocalStorage() {
    this.authservice.localStorage();
    this.router.navigate(["tasks"]);
  }
}
