import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./guards/Auth.guard";

import { TasklistComponent } from "./tasklist/tasklist.component";
import { LoginComponent } from "./login/login.component";
import { TaskeditorComponent } from "./taskeditor/taskeditor.component";
import { TTypeSelectComponent } from "./ttypeselect/ttypeselect.component";
import { ShoplistComponent } from "./shoplist/shoplist.component";

const routes: Routes = [
  { path: "", redirectTo: "/tasks", pathMatch: "full" },
  { path: "ttypes", component: TTypeSelectComponent, canActivate: [AuthGuard] },
  { path: "tasks", component: TasklistComponent, canActivate: [AuthGuard] },
  {
    path: "editor/:TaskID/:TaskTypeID",
    component: TaskeditorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "shoplist/:TaskID/:TaskTypeID",
    component: ShoplistComponent,
    canActivate: [AuthGuard]
  },
  { path: "login", component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
