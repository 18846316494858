import { Component, OnInit, Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from "@angular/fire/firestore";

import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import * as firebase from "firebase/app";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { Task } from "../../models/Task";
import { TaskType } from "../../models/TaskType";
import { AuthService } from "../../services/auth.service";
import { SpeechRecognitionService } from "./../../services/speech-recognition.service";

@Component({
  selector: "app-taskeditor",
  templateUrl: "./taskeditor.component.html",
  styleUrls: ["./taskeditor.component.css"]
})
export class TaskeditorComponent implements OnInit {
  TaskID: number;
  TaskTypeID: string;
  data: any;
  fbSubs: any;
  orgData: Task;
  private speechsub: any;

  constructor(
    public db: AngularFirestore,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthService,
    public speechRec: SpeechRecognitionService
  ) {}

  ngOnInit() {
    this.fbSubs = this.route.params.subscribe(params => {
      this.TaskID = params["TaskID"];
      this.TaskTypeID = params["TaskTypeID"];
      this.db
        .collection(this.auth.currUserPath() + "/tasks/")
        .doc(this.TaskID.toString())
        .get()
        .subscribe(tsk => {
          if (tsk.exists) {
            this.data = tsk.data();
          } else {
            this.data = new Task(this.TaskID, this.TaskTypeID);
          }
        });
    });
  }

  ngOnDestroy() {
    //anything to be destroyed ??
    this.fbSubs.unsubscribe();
    /*  this.speechsub.unsubscribe(); */
  }

  goBack() {
    //window.history.back();
    this.router.navigate(["tasks"]);
  }

  onSaveClicked() {
    var obs: any;
    this.db
      .collection(this.auth.currUserPath() + "/tasks/")
      .doc(this.TaskID.toString())
      .set(Object.assign({}, this.data));
    this.router.navigate(["tasks"]);
  }

  startRecording() {
    this.speechRec.record().subscribe(text => {
      this.data.TaskDesc = this.data.TaskDesc + " " + text;
    });
  }

  onCancelClicked() {
    Object.assign(this.data, this.orgData);
    this.router.navigate(["tasks"]);
  }
}
