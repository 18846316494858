import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable, Subject } from "rxjs";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireAuth } from "@angular/fire/auth";
import { AuthService } from "../../services/auth.service";
import { take, map } from "rxjs/operators";
import * as firebase from "firebase/app";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private afa: AngularFireAuth,
    protected router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // when the user is logged in and just navigated to another route...
    if (this.authService.isLoggedIn.value) {
      return true;
    }

    // proceeds if not loggedIn or F5/page refresh

    // Store the attempted URL for redirecting later
    this.authService.redirectUrl = state.url;

    // go login page
    this.router.navigate(["/login"]);
    return false;
  }
  /* canActivate(): boolean {
      var loggedIn = this.auth.isLoggedIn()
      if (!loggedIn && !this.auth.local) {
        this.router.navigate(['/login']);
        return false;
      } else {
        return true;
      }
    } */
}
