import { Component, OnInit, Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from "@angular/fire/firestore";

import { Task, ShoppingItem } from "../../models/Task";
import { TaskType } from "../../models/TaskType";
import { AuthService } from "../../services/auth.service";
import { SpeechRecognitionService } from "./../../services/speech-recognition.service";

import { TaskeditorComponent } from "./../taskeditor/taskeditor.component";

@Component({
  selector: "app-shoplist",
  templateUrl: "./shoplist.component.html",
  styleUrls: ["./shoplist.component.scss"]
})
export class ShoplistComponent extends TaskeditorComponent {
  public CurrentItem: string;

  constructor(
    db: AngularFirestore,
    router: Router,
    route: ActivatedRoute,
    auth: AuthService,
    speechRec: SpeechRecognitionService
  ) {
    super(db, router, route, auth, speechRec);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  removeCurrentItem() {
    this.CurrentItem = "";
  }
  addItem() {
    //var item: ShoppingItem;
    //item = new ShoppingItem();

    if (this.CurrentItem && this.CurrentItem != "") {
      //item.Issue = this.CurrentItem;
      this.data.Items.push(this.CurrentItem);
      if (this.data.TaskDesc === "") {
        this.data.TaskDesc = this.CurrentItem;
      }
      this.CurrentItem = "";
    }
  }

  removeItem(item: string) {
    debugger;
    this.data.Items = this.data.Items.filter(el => {
      return el != item;
    });
  }

  startRecording() {
    this.speechRec.record().subscribe(text => {
      this.CurrentItem = text;
    });
  }
}
